import React from "react";
import {MuiThemeContext} from "./src/theme/themeContext";

export const wrapRootElement = ({ element }) => {
  return element
};

export const wrapPageElement = ({ element, props }) => {
  return (
      <MuiThemeContext location={props.location}>
        {element}
      </MuiThemeContext>
  )
}




