import styled, {keyframes} from "styled-components";
import "../../global/main.css"
import {mediaQuery} from "../../global/helpers/styleHelpers";

const movingCircleOne = keyframes`
  0%   { transform: scale(1)   translate(10px, -30px); }
  38%  { transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg); }
  40%  { transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg); }
  78%  { transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg); }
  80%  { transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg); }
  100% { transform: scale(1)   translate(10px, -30px); }
`

const glowingCircleOne = keyframes`
  0%   { transform: scale(1)   translate(0px, -15px); }
  38%  { transform: scale(0.8, 1) translate(10px, -30px) }
  40%  { transform: scale(0.8, 1) translate(10px, -30px) }
  78%  { transform: scale(1.3) translate(50px, -50px) }
  80%  { transform: scale(1.3) translate(20px, -40px) }
  100% { transform: scale(1) translate(10px, -30px) }
`

const glowingCircleTwo = keyframes`
  0%   { transform: scale(1)   translate(0px, -15px); }
  38%  { transform: scale(0.8, 1) translate(-10px, -30px) }
  40%  { transform: scale(0.8, 1) translate(-10px, -30px) }
  78%  { transform: scale(1.3) translate(-50px, -50px) }
  80%  { transform: scale(1.3) translate(-20px, -40px) }
  100% { transform: scale(1) translate(-10px, -30px) }
`

const movingCircleTwo = keyframes`
  0%   { transform: scale(1)   translate(-10px, 30px); }
  38%  { transform: scale(0.8, 1) translate(-80vw, 30vh) rotate(160deg); }
  40%  { transform: scale(0.8, 1) translate(-80vw, 30vh) rotate(160deg); }
  78%  { transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg); }
  80%  { transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg); }
  100% { transform: scale(1)   translate(-10px, 30px); }
`

const revealBG = keyframes`
  0%   { opacity: 0 }
  10%   { opacity: 0 }
  20%   { opacity: 0.1 }
  30%   { opacity: 0.2 }
  50%   { opacity: 0.4 }
  100% { opacity: .85 }
`

export const Circle = styled.div`
  height: 1000px;
  width: 1000px;
  position: absolute;
  border-radius: 100%;
  @media (max-width: ${mediaQuery("md")}) {
    height: 450px;
    width: 450px;
  }
  &.circle-one {
    background-color: #AFBCAC;
    top: -500px;
    left: -175px;
    @media (max-width: ${mediaQuery("md")}) {
      top: -5%;
      left: -40%;
      animation-name: ${glowingCircleOne};
      animation-duration: 15s;
      animation-direction: alternate;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
    @media (min-width: ${mediaQuery("md")}) {
      animation-name: ${movingCircleOne};
      animation-duration: 30s;
      animation-direction: alternate;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
  }
  &.circle-two {
    background-color: #DBC4B4;
    bottom: 50px;
    right: 125px;
    @media (max-width: ${mediaQuery("md")}) {
      bottom: 5%;
      right: -15%;
      animation-name: ${glowingCircleTwo};
      animation-duration: 15s;
      animation-direction: alternate;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
    @media (min-width: ${mediaQuery("md")}) {
      animation-name: ${movingCircleTwo};
      animation-duration: 30s;
      animation-direction: alternate;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-delay: 5s;
    }
  }
`;

export const HomePageStyle = styled.div`
  pointer-events: none;
  background: rgba(255,255,255,.65);
  backdrop-filter: blur(65px); 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0;
  width: 100%;
  overflow: hidden;
  @media (max-width: ${mediaQuery("md")}) {
    background: rgba(255,255,255,.70);
    justify-content: flex-end;
    align-items: flex-start;
    backdrop-filter: blur(30px);
  }
`;

export const Title = styled.div`
  position: absolute;
  z-index: 3;
  top:10%;
  @media (max-width: ${mediaQuery("md")}) {
    top:7%;
  }
  & .title-name {
    text-align: center;
    color: var(--squirrel);
    font-family: var(--font-family-playfair_display);
    font-size: 24px;
  }
  & .title-and {
    text-align: center;
    color: var(--wafer);
    font-family: var(--font-family-playfair_display);
    font-size: 64px;
  }
  & .title-stephanie {
    margin-top: 15px;
  }
  & .title-eric {
    margin-top: 40px;
  }
`
export const TitleSmall = styled.div`
  & .title-name {
    text-align: center;
    color: var(--squirrel);
    font-family: var(--font-family-playfair_display);
    font-size: 22px;
  }
  & .title-and {
    text-align: center;
    color: var(--wafer);
    font-family: var(--font-family-playfair_display);
    font-size: 48px;
  }
  & .title-stephanie {
    margin-top: 10px;
  }
  & .title-eric {
    margin-top: 28px;
  }
`

export const SubTitle = styled.div`
  position: absolute;
  z-index: 4;
  top:25%;
  width: 75%;
  font-family: var(--font-family-mrs_saint_delafield);
  color: var(--squirrel);
  font-size: 28px;
  text-align: right;
`
export const MenuContainer = styled.div`
  //background: yellow;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  padding: 50px;
  width:1444px;
  max-width: 85%;
  height: 900px;
  max-height: 75%;
  position: relative;
  @media (max-width: ${mediaQuery("md")}) {
    padding: 50px 0 50px 20px;
    max-width: 98%;
    height: 100%;
  }
  & .overflow-menu-one {
    left:0;
    top:0;
    width:100%;
    padding-top: 10px;
  }
  & .overflow-menu-two {
    cursor: pointer;
    left:0;
    top:0;
    height: 100%;
  }
  & .overflow-menu-three {
    right:0;
    top:0;
    height: 100%;
    @media (max-width: ${mediaQuery("md")}) {
      top: unset;
      left:0;
      bottom:0;
      height: 50px;
      width:100%;
      padding-bottom: 10px;
    }
  }
`;

export const MenuLine = styled.div`
  background: var(--wafer);
  width:40px;
  height: 2px;
  margin: 0 5px;
`;

export const MenuItemText = styled.div`
  min-width: ${props => props.minWidth ? `${props.minWidth}px` : "150px"};
  text-align: center;
  color: var(--squirrel);
  font-family: var(--font-family-vollkorn);
  font-size: 18px;
`;

export const MenuItemRotated = styled.div`
  transform: rotate(${props => props.rotation ? `${props.rotation}deg` : 0});
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const MenuItem = styled.div`
  pointer-events: auto;
  width:50px;
  height: 50px;
  @media (max-width: ${mediaQuery("md")}) {
    width:100px
  }
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const OverflowContainer = styled.div`
  //background: red;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: ${mediaQuery("md")}) {
    justify-content: flex-end;
    //align-items: flex-start;
  }
  width:100%;
  height: 100%;
  position: relative;
  & .overflow-item-one {
    left:0;
    top:0;
    background-image: url("/overflow_item_one.png");
    background-size: cover;
    background-position: center;
    height: 150px;
    width:100px;
    @media (max-width: ${mediaQuery("md")}) {
      width:85px;
      height:110px;
    }
  }
  & .overflow-item-two {
    right:0;
    bottom: 0;
    background-image: url("/overflow_item_two.png");
    background-size: cover;
    background-position: center;
    height: 150px;
    width:150px;
    @media (max-width: ${mediaQuery("md")}) {
      width:115px;
      height:115px
    }
  }
`;

export const OverflowItem = styled.div`
  //background: blue;
  padding: 15px;
  width:100px;
  height: 100px;
  position: absolute;
  z-index: 2;
  opacity: 0.9;
`;

export const MainContainer = styled.div`
  //background: grey;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  width: calc(100% - 75px);
  height: calc(100% - 75px);
  overflow: hidden;
`;

export const BGContainer = styled.div`
  //background-color: green;
  width:1440px;
  height: 700px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: ${mediaQuery("md")}) {
    height: 100%;
    //align-items: flex-start;
  }
`;

export const BGImage = styled.div`
  min-width: calc(100% - 5px);
  min-height: calc(100% - 5px);
  background-image: url("/background.jpeg");
  background-size: cover;
  background-position: 50% 80%;
  position: absolute;
  opacity: 0.85;
  top:0;
  right: 0;
  z-index: 0;
  animation-name: ${revealBG};
  animation-duration: 1s;
  animation-timing-function: ease-out;
  //animation-iteration-count: infinite;
  //box-shadow: 0 0 5px 5px white inset;
  @media (max-width: ${mediaQuery("md")}) {
    right: 0;
    //align-items: flex-start;
  }
  &.background-image-blur {
    min-width:100%;
    min-height: 100%;
    filter: blur(20px);
  }
`;

export const BGImageShade = styled.div`
  width:105%;
  height: 100%;
  background: linear-gradient(#fff, #fff 15%, rgba(255,255,255,0) 50%);
  background-size: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index: 1;
`;
