import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import {graphql, navigate, useStaticQuery} from "gatsby";
import {List, ListItem, ListItemText, Slide, Stack, SwipeableDrawer} from "@mui/material";
import {Close} from "@mui/icons-material";
import {TitleSmall} from "../../../templates/homepage/style";


const DrawerItems = ({list, toggleDrawer, pathname}) => {
  return (
          <Box
              sx={{width: "100vw", pb:4}}
              onClick={() => toggleDrawer(false)}
              onKeyDown={() => toggleDrawer(false)}
          >
            <IconButton onClick={() => toggleDrawer(false)} sx={{ml:"auto", p:2}}><Close/></IconButton>
            <List sx={{pt:2}}>
              {list?.length && list.map((page, index) => {
                let title;
                switch(page.path){
                  case "/":
                    title = "Accueil"
                    break
                  case "/le-mariage/":
                    title = "Le Mariage"
                    break
                  case "/notre-lune-de-miel/":
                    title = "Notre lune de miel"
                    break
                  case "/galerie/":
                    title = "Galerie"
                    break
                  default: return <React.Fragment/>
                }

                return (
                    <>
                      <ListItem
                          button
                          key={index}
                          onClick={() => navigate(page.path)}
                          color={"primary"}
                          sx={{bgcolor:pathname === page.path ? "primary.main" : "lowContrast.main", width:"90%", margin:"5px auto", borderRadius:"0 10px 0 10px"}}
                      >
                        <ListItemText
                            primary={title}
                            primaryTypographyProps={{
                              color:pathname === page.path ? "secondary.contrastText" : "primary.main",
                              textAlign:"center",
                              lineHeight:2,
                              fontSize: 18
                            }}
                        />
                      </ListItem>
                    </>
                )
              })}
            </List>
          </Box>
      )
};

export const Header = ({location}) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [open, setOpen] = useState(false);

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
       allSitePage {
        nodes {
          path
        }
      }
    }
  `)

  const toggleDrawer = (flag) => {
    setOpen(flag);
  };


  return (
      <Slide direction="down" in={true} appear={true} mountOnEnter unmountOnExit>
      <AppBar position="fixed" color={"blurred"}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => toggleDrawer(true)}
                  color="primary"
              >
                <MenuIcon />
              </IconButton>
              <SwipeableDrawer
                  anchor={"top"}
                  open={open}
                  onClose={() => toggleDrawer(false)}
                  onOpen={() => toggleDrawer(true)}
              >
                <DrawerItems list={data?.allSitePage.nodes} toggleDrawer={toggleDrawer} pathname={location.pathname}/>
              </SwipeableDrawer>
            </Box>
            <Box sx={{flexGrow: 1}} onClick={() => navigate("/")} style={{cursor:"pointer"}}>
              <TitleSmall>
                <Stack direction={"row"}>
                  <div className={"title-name title-stephanie"}><span>Stéphanie</span></div>
                  <div className={"title-and"}><span>&</span></div>
                  <div className={"title-name title-eric"}><span>Eric</span></div>
                </Stack>
              </TitleSmall>
            </Box>
            <Box sx={{display: { xs: 'none', md: 'flex' } }}>
              {data?.allSitePage?.nodes.map((page) => {
                  let title;
                  switch(page.path){
                    case "/":
                      title = "Accueil"
                      break
                    case "/le-mariage/":
                      title = "Le Mariage"
                      break
                    case "/notre-lune-de-miel/":
                      title = "Notre lune de miel"
                      break
                    case "/galerie/":
                      title = "Galerie"
                      break
                    default: return <React.Fragment key={page.id}/>
                  }
                  return (
                      <Button
                        variant={"highContrast"}
                        key={page.id}
                        onClick={() => navigate(page.path)}
                        sx={{ my: 2, color: 'white', display: 'block' }}
                      >
                        {title}
                      </Button>
                    )
              })}
            </Box>
            <Box sx={{ flexGrow: 0, width:50}}/>
          </Toolbar>
        </Container>
      </AppBar>
      </Slide>
  );
};
