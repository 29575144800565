import React, {createContext, useMemo, useState} from "react";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {responsiveFontSizes, useMediaQuery} from "@mui/material";
import {orange, red} from "@mui/material/colors";

import {Layout} from "../global/layout";

let theme = createTheme({
  palette: {
    primary: {
      main: '#917D78FF',
      contrastText: '#fff',
    },
    secondary: {
      main: '#D9C6C4FF',
      contrastText: '#fff',
    },
    mint: {
      main: '#87B89E',
      contrastText: '#fff',
    },
    forest: {
      main: '#26312b',
      contrastText: '#fff',
    },
    lightMint: {
      main: '#EEFAF2',
      contrastText: '#fff',
    },
    white: {
      main: '#fff',
      contrastText: '#917D78FF',
    },
    accent: {
      main: '#312929FF',
      contrastText: '#ECE5E5FF',
    },
    lowContrast: {
      main: '#ECE5E5FF',
      contrastText: '#917D78FF',
    },
    warning: {
      main: orange[400],
      contrastText: orange[900],
    },
    blurred:   {
      main: 'rgba(255,255,255,.65)',
      paper: 'rgba(255,255,255,.65)',
    },
    text: {
      primary: "#917D78FF",
      secondary: '#fff',
      accent: '#312929FF',
      mint: '#87B89E',
      warning: orange[500],
      error: red[500]
    },
  }
});

theme = responsiveFontSizes(theme, {factor: 4});

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

export const MuiThemeContext = ({children, location}) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState(prefersDarkMode ?  'dark' : 'light');

  const colorMode = useMemo(
      () => ({
        toggleColorMode: () => {
          setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        },
      }),
      [],
  );

  return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <Layout location={location}>
            {children}
          </Layout>
        </ThemeProvider>
      </ColorModeContext.Provider>
  );
}