module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Vollkorn"]}},
    },{
      plugin: require('../plugins/one-drive-source-plugin/gatsby-browser.js'),
      options: {"plugins":[],"apiUrl":"https://graph.microsoft.com/v1.0","apiToken":"EwB4A8l6BAAUkj1NuJYtTVha+Mogk+HEiPbQo04AAd5/Yusfj+zBlBOOGhf6KwNQsFiJkiPepnlMEgL/BOUP1f0v/IOLI+PjHyOKDlinJxelCQH8jJrf8/K/19j2fKqUfGKyCG6WH85pxD6K0yzN4XTAgIkeYgvfZAGyH7CASZibSfe2NW+JmQ2EsFd8P4ExRhEutizSadmg0n+csjj1ffri/Pmjf32NMsHWGK65oGuPMiesGGw7tyNnnVdiSvWeJBIGwUJxYiRW88ZzeWpWunqhVaONjn6+gG5tKigb5kWFw4aqWZyVbHOqpOJPP204OTA29T0e5DW5PelnItvAiEQIPeuxqRp0otoqF6CkT9vFoRy+dMRoGm8yxiDHwX8DZgAACAQhq2bW9W7SSAKMZ4rz9kYV1GSK19suPtnnHDnWlO7hYPXWRCDpeKgR5Sey5Of6V6mtDfhMX2Ez2Eh6oeng+QLSvzwq2xw9Ds5oaPuAGvZVQk9zK4p1vLmQ+mJADGFGZHEh8icfqV6dpe7YJLUSZJjEe6MLgqdg3ID1X/v6lf5QdhkcJGVexy19B/RGn/rN0t+xqJkp2BO1PN2mEr9YcX5eNXNZWERy0RBr8xYPT6mKChZDMDf0jYnuGBYrhqhTACBtcgyfeQA92sCSUbHGEyVXIIwGu9kfPyOP3GKIo2ippempGhdeCyQidGN9vBTk7JcR+z2m+NCnrz4wDosvlRWC1PK1vxH1hVSqdyxivM9T4iugjX9FOFKsVgld4SQsQlNIibl3U1//JCMLpNbpbnAnthN17dRdKEi+l1NMOsEW5K0kxY34sMHWiwI1s4f1q1VDxkYVQcSnX4veD8WvZoAGnwzCaZ5yjFclx+aWveFj7pT3ZEu04WwLJndiYcZhBaX2XdpEYJgoU/jpZd3+wXTdImVr+g6ARBzzgU+sPMUGRYI+d3gzaTUQiGqVIpm3BrK3gaYIDpvOmpdCtC41hP7up8wB6jnFYatzoH6kKQ6KDybUGxB2og6y33IND/vxUhIRApQ8pyoW9+oH7zw6WNlRpUa1hVm1oQg0myCEg73dawJdUJhMOkW5MSAduNjP7ZNI3bELoQJVKKJrPuszfRVrYPPIxgW3Gf/KC/O+pw/lUearwAGGnzC4b2n8YCeMZFP7LCRCUZWhcNsTWQXF5/+8yYoC","mainFolderId":"AD3DE2E947C5C221!6104","incremental":true,"verbose":{"data":true,"type":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
