import React from "react"
import {Header} from "./organism/header/header";
import {Fade} from "@mui/material";

export const Layout = ({children, location}) => {

  if(location.pathname === "/"){
    return children
  } else {
    return (
      <Fade in={true} appear={true} timeout={500}>
        <div>
          <Header location={location}/>
          {children}
        </div>
      </Fade>
    )
  }
}